import { connect } from 'react-redux';
import isBotCheck from 'isbot';
import { bindActionCreators, Dispatch } from 'redux';

import Article from 'models/Article';
import Location from 'models/Location';
import Market from 'models/Market';
import ProductCategory from 'models/ProductCategory';
import RedirectModel from 'models/Redirect';

import Redirect from 'services/Redirect';

import {
    current as currentCart,
    setCurrent as setCurrentCart,
} from 'store/modules/carts/actions';
import { profileGet, setAuthToken } from 'store/modules/user/actions';
import {
    set as setVisitor,
    submit as submitVisitor,
} from 'store/modules/visitors/actions';
import { State } from 'store/state';

import Component from './component';
import { Props } from './types';

interface ExtendedProcess extends NodeJS.Process {
    data?: any;
}

const mapStateToProps = (state: State, ownProps?: Props): object => {
    return {
        state: {
            visitor: state?.visitor?.data,
            authToken: state.user.authToken,
            profile: state.user.profile,
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
    return {
        actions: {
            setVisitor: bindActionCreators(setVisitor, dispatch),
            submitVisitor: bindActionCreators(submitVisitor, dispatch),
            currentCart: bindActionCreators(currentCart, dispatch),
            setCurrentCart: bindActionCreators(setCurrentCart, dispatch),
            profileGet: bindActionCreators(profileGet, dispatch),
            setAuthToken: bindActionCreators(setAuthToken, dispatch),
        },
    };
};

Component.getInitialProps = async (appContext: any): Promise<Props> => {
    // Handle market data
    let market: Market = null;
    let navLocations: Location[] = [];
    let navArticles: Article[] = [];
    let isMobile = false;
    let isBot = false;
    let productCategories = [];

    if (typeof window !== 'undefined') {
        const nextData = document.getElementById('__NEXT_DATA__');
        const nextDataText = nextData.innerText;
        const nextDataJson = JSON.parse(nextDataText);
        const pageProps = nextDataJson?.props?.initialProps?.pageProps;
        market = pageProps?.market;
        navLocations = pageProps?.navLocations;
        navArticles = pageProps?.navArticles || [];
        isMobile = pageProps?.isMobile;
        isBot = pageProps?.isBot;
        productCategories = pageProps?.productCategories;
    } else {
        const extendedProcess: ExtendedProcess = { ...process };

        // Handle redirects
        const redirectModels = Array.isArray(extendedProcess?.data?.redirects?.elements)
            ? extendedProcess?.data?.redirects.elements.map(redirectResource => new RedirectModel(redirectResource))
            : [];
        Redirect.checkRedirect(appContext, redirectModels);

        // Handle device
        const userAgent = appContext.ctx.req.headers['user-agent'];
        isMobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

        //Handle bots
        isBot = isBotCheck(appContext.ctx.req.headers['user-agent']);

        market = new Market(extendedProcess?.data?.market);
        market = JSON.parse(JSON.stringify(market));

        const locationModels = Array.isArray(extendedProcess?.data?.locations?.elements)
            ? extendedProcess?.data?.locations.elements.map(locationResource => new Location(locationResource))
            : [];
        navLocations = JSON.parse(JSON.stringify(locationModels));

        const articleModels = Array.isArray(extendedProcess?.data?.articles)
            ? extendedProcess?.data?.articles.map(articleResource => new Article(articleResource))
            : [];
        navArticles = JSON.parse(JSON.stringify(articleModels));

        const productCategoryModels = Array.isArray(extendedProcess?.data?.productCategories?.elements)
            ? extendedProcess?.data?.productCategories.elements.map(productCategoryResource => new ProductCategory(productCategoryResource))
            : [];
        productCategories = JSON.parse(JSON.stringify(productCategoryModels));
    }

    return {
        market,
        navLocations,
        navArticles,
        isMobile,
        isBot,
        productCategories,
    };
};

Component.defaultProps = {
    children: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
