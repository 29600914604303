import moment, { Moment } from 'moment-timezone';

import ApiProduct from 'types/api/Product';
import { Locales } from 'types/locale';
import { Option } from 'types/options';

import { Types } from 'models/CartItem';
import CmsSection, { SectionData } from 'models/CmsSection';
import { Timezone } from 'models/Market';
import Model from 'models/Model';
import ProductBodyPart from 'models/ProductBodyPart';
import ProductCategory from 'models/ProductCategory';
import ProductComparison from 'models/ProductComparison';
import ProductFAQ from 'models/ProductFAQ';
import ProductInfo from 'models/ProductInfo';
import ProductLocale from 'models/ProductLocale';
import ProductPricing from 'models/ProductPricing';
import ProductPricingMatrix from 'models/ProductPricingMatrix';
import ProductPromo from 'models/ProductPromo';
import ProductStage from 'models/ProductStage';

import { getFormattedDate } from 'utils/date';
import { select as selectLocale } from 'utils/locale';
import { changeFromCent, displayPrice } from 'utils/math';

import ProductLists from './ProductLists';

export interface PricingMatrixDataPricing {
    quantityFrom: number;
    quantityTo: number;
    price: number;
    priceDisplay: string;
}

export enum Genders {
    Male = 'male',
    Female = 'female',
}

export enum Gender {
    gender = 'gender',
}

export enum Visibilities {
    Homepage = 'homepage',
    Pricing = 'pricing',
    ManWomanZone = 'manWomanZone',
    ThankYouPage = 'thankYouPage',
    Search = 'search',
    Silhouette = 'silhouette',
    SpecialOffers = 'specialOffers',
    Location = 'location',
    Article = 'article',
    Endermology = 'endermology',
    EmSpec = 'emSpec',
    Hydrogen = 'hydrogen',
    Dermapen = 'dermapen',
}

//TODO: move to types
export enum ProductTypes {
    Product = 'product',
    Package = 'package',
    Voucher = 'voucher',
    Bundle = 'bundle',
}

export interface CmsSectionsTypes extends CmsSection {
    sectionTypeName: string;
    sectionData: SectionData;
}

const translationsModels = selectLocale({
    [Locales.Pl]: require('locales/models/pl.json'),
    [Locales.En]: require('locales/models/en.json'),
});

export default class Product implements Model {
    origin: ApiProduct;
    id: string;
    type: Types;
    name: string;
    duration: number;
    model: Product;
    gender: Genders;
    promoValidTo?: Moment;
    promoValidToDisplay?: string;
    genderOption: Option<Genders>;
    category: ProductCategory;
    categoryOption: Option<ProductCategory>;
    productType: ProductTypes;
    productTypeOption: Option<Types>;
    packageQuantity?: number;
    recommendedQuantityFrom: number;
    recommendedQuantityTo: number;
    cmsSections: CmsSectionsTypes[];
    productBodyParts: ProductBodyPart[];
    promoRecommended: boolean;
    isSpecialOffer: boolean;
    genderZoneSlug: string;
    showLowestPrice?: boolean;
    showProductDuration?: boolean;
    productDurationTo?: Moment;
    productDurationToDisplay?: {
        day: string;
        hour: string;
    };
    displayLowestPriceValue?: string;
    lowestPriceValue?: number;

    infos: ProductInfo[];
    stages?: ProductStage[];
    faqs: ProductFAQ[];
    lists: ProductLists[];
    comparisons: ProductComparison[];

    locale: ProductLocale;
    pricing: ProductPricing;

    pricingMatrixes: ProductPricingMatrix[];
    defaultPricingMatrix: ProductPricingMatrix;

    ongoingPromos: ProductPromo[];
    hasOngoingPromos: boolean;
    ongoingPromo: ProductPromo;
    defaultOngoingPromoPricingMatrix: ProductPricingMatrix;
    promoPercent: number;

    finalPricingMatrixes: ProductPricingMatrix[];
    finalPricingMatrixOptions: Option<number>[];
    isBundlable: boolean;

    bodyPartCount?: number;
    hasCheckbox: boolean;
    checkboxLabel?: string;
    canPaymentByCash?: boolean;

    constructor(data: ApiProduct) {
        this.origin = data;
        this.id = data.id;
        this.type = Types.Product;
        this.name = data.name;
        this.duration = data.duration;
        this.gender = data.gender;
        this.promoValidTo = data.promoValidTo && moment.utc(data.promoValidTo).tz(Timezone);
        this.promoValidToDisplay = this.promoValidTo && getFormattedDate(this.promoValidTo, 'date');
        this.genderOption = this.getGenderOption(this.gender);
        this.category = data.category && new ProductCategory(data.category);
        this.categoryOption = this.category && this.category?.getOption();
        this.productType = data.type;
        this.packageQuantity = data.packageQuantity;
        this.recommendedQuantityFrom = data.recommendedQuantityFrom;
        this.recommendedQuantityTo = data.recommendedQuantityTo;
        this.cmsSections = data.cmsSections;
        this.productBodyParts = Array.isArray(data.productBodyParts)
            ? data.productBodyParts.map(productBodyPart => new ProductBodyPart(productBodyPart))
            : [];
        this.promoRecommended = data.promoRecommended;
        this.isSpecialOffer = data.isSpecial;
        this.genderZoneSlug = this.getGenderZoneSlug(this.gender);
        this.showLowestPrice = data.showLowestPrice;
        this.showProductDuration = data.showProductDuration;
        this.productDurationTo = data.productDurationTo && moment.utc(data.productDurationTo).tz(Timezone);
        this.productDurationToDisplay = {
            day: this.productDurationTo && getFormattedDate(this.productDurationTo, 'date'),
            hour: this.productDurationTo && getFormattedDate(this.productDurationTo, 'time'),
        };

        this.locale = data.locale && new ProductLocale(data.locale) || null;
        this.pricing = data.pricing && new ProductPricing(data.pricing) || null;

        this.lowestPriceValue = data.lowestPriceValue && changeFromCent(data.lowestPriceValue);
        this.displayLowestPriceValue = data.pricing ? displayPrice(this.lowestPriceValue, this.pricing.currency) : `${this.lowestPriceValue}`;

        this.pricingMatrixes = Array.isArray(data.pricingMatrix)
            ? data.pricingMatrix
                .map(element => new ProductPricingMatrix(element))
                .sort((elemA, elemB) => elemA.quantityFrom - elemB.quantityFrom)
            : [];
        this.defaultPricingMatrix = this.pricingMatrixes.find(pricingMatrix => pricingMatrix?.quantityFrom === 1);


        this.ongoingPromos = Array.isArray(data.ongoingPromos)
            ? data.ongoingPromos.map(ongoingPromo => new ProductPromo(ongoingPromo))
            : [];
        this.hasOngoingPromos = this.ongoingPromos.length > 0;
        this.ongoingPromo = this.getOngoningPromo();
        this.defaultOngoingPromoPricingMatrix = this.hasOngoingPromos && this.ongoingPromo.pricingMatrixes.find(pricingMatrix => pricingMatrix?.quantityFrom === 1);
        this.promoPercent = this.productType === ProductTypes.Package
            ? this.getPromoPercent(this.pricing?.price, this.ongoingPromo?.pricing?.price)
            : this.getPromoPercent(this.defaultPricingMatrix?.price, this.defaultOngoingPromoPricingMatrix?.price);

        this.finalPricingMatrixes = this.getFinalPricingMatrixes();
        this.finalPricingMatrixOptions = this.getFinalPricingMatrixOptions();

        this.infos = Array.isArray(data.infos)
            ? data.infos
                .map(element => new ProductInfo(element))
                .sort((elemA, elemB) => elemA.order - elemB.order)
            : [];
        this.faqs = Array.isArray(data.faqs)
            ? data.faqs
                .map(element => new ProductFAQ(element))
                .sort((elemA, elemB) => elemA.order - elemB.order)
            : [];
        this.comparisons = Array.isArray(data.comparisons)
            ? data.comparisons
                .map(element => new ProductComparison(element))
                .sort((elemA, elemB) => elemA.order - elemB.order)
            : [];
        this.stages = Array.isArray(data.stages)
            ? data.stages
                .map(stage => new ProductStage(stage))
                .sort((elemA, elemB) => elemA.order - elemB.order)
            : [];
        this.lists = Array.isArray(data.lists)
            ? data.lists
                .map(stage => new ProductLists(stage))
                .sort((elemA, elemB) => elemA.order - elemB.order)
            : [];
        this.isBundlable = data.isBundlable;

        this.bodyPartCount = data.bodyPartCount || 0;
        this.hasCheckbox = data.hasCheckbox;
        this.checkboxLabel = data.checkboxLabel;
        this.canPaymentByCash = data.canPaymentByCash;
    }

    getGenderZoneSlug = (gender: Genders): string => {
        switch (gender) {
            case Genders.Male:
                return translationsModels?.product?.genderZones?.male;
            default:
                return translationsModels?.product?.genderZones?.female;
        }
    };

    getGenderOption = (value: Genders): Option<Genders> => {
        return genderOptions.find(option => option.value === value);
    };

    getOngoningPromo = (): ProductPromo => {
        if (!this.hasOngoingPromos) return null;

        return this.ongoingPromos[0];
    };

    getDefaultPrice = (): number => {
        if (Array.isArray(this.pricingMatrixes)) {
            if (this.defaultPricingMatrix) {
                return this.defaultPricingMatrix?.price;
            }
        }

        return this.pricing?.price || 0;
    };

    getFinalPricingMatrixes = (): ProductPricingMatrix[] => {
        let finalPricingMatrixes: ProductPricingMatrix[] = [];

        if (this.hasOngoingPromos) {
            if (!Array.isArray(this.ongoingPromo.pricingMatrixes)) return [];

            finalPricingMatrixes = this.ongoingPromo.pricingMatrixes;
        } else {
            if (!Array.isArray(this.pricingMatrixes)) return [];

            finalPricingMatrixes = this.pricingMatrixes;
        }

        return finalPricingMatrixes;
    };

    getFinalPricingMatrixOptions = (): Option<number>[] => {
        return Array.isArray(this.finalPricingMatrixes) && this.finalPricingMatrixes.map(pricingMatrix => {
            const price = pricingMatrix?.price * pricingMatrix?.quantityFrom;
            const priceDisplay = displayPrice(price, pricingMatrix?.currency);
            const pricePriceDisplay = displayPrice(pricingMatrix?.price, pricingMatrix?.currency);
            const promoProcent = this.getPromoPercent(this.defaultPricingMatrix?.price, pricingMatrix?.price);

            return ({
                label: `${pricingMatrix?.quantityFrom} ${translationsModels?.product?.preposition} ${pricePriceDisplay}`,
                value: pricingMatrix?.quantityFrom,
                perProcedure: pricePriceDisplay,
                price,
                priceDisplay,
                data: {
                    regularPriceDisplay: displayPrice(this.pricing?.price * pricingMatrix?.quantityFrom, pricingMatrix?.currency),
                    promoProcent,
                },
            });
        }) || [];
    };

    getPromoPercent = (price: number, promoPrice: number): number => {
        if (!promoPrice) return 0;

        const difference = price - promoPrice;
        const promoPercent = (difference / price) * 100;

        return Math.round(promoPercent);
    };

    getMatrixPrice = (matrixes: ProductPricingMatrix[], quantity: number): ProductPricingMatrix | null => {
        return matrixes.find(matrix => {
            if (quantity < matrix.quantityFrom) {
                return false;
            }

            return quantity <= matrix.quantityTo;
        });
    };

    getOption = (): Option<Product> => {
        return {
            value: this,
            label: this.locale?.name || '',
        };
    };

    getProductBodyPartOption = (): Option<Product> => {
        return {
            value: this,
            label: this.productBodyParts[0]?.bodyPart?.name || this.locale?.name || '',
        };
    };

    getName = (): string => {
        return this.productBodyParts[0]?.bodyPart?.name ?? this.locale.name;
    };
}

export const genderSpecialOfferOptions = [{
    value: Genders.Female,
    label: translationsModels?.specialOffer?.genders?.female,
}, {
    value: Genders.Male,
    label: translationsModels?.specialOffer?.genders?.male,
}];

export const genderOptions = [{
    value: Genders.Female,
    label: translationsModels?.offer?.genders?.female,
}, {
    value: Genders.Male,
    label: translationsModels?.offer?.genders?.male,
}];