import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import moment from 'moment-timezone';

import { CookieKeys } from 'types/cookies';
import { Locales } from 'types/locale';
import { LocalStorageKeys } from 'types/localStorage';
import { Routes } from 'types/routes';

import Cart from 'models/Cart';
import Visitor from 'models/Visitor';

import { getLocale, getLocaleConfig, select as selectLocale } from 'utils/locale';
import { getWrapperName } from 'utils/route';
import { generateHash } from 'utils/security';
import { getProvider } from 'services/Cookies';
import LocalStorage from 'services/LocalStorage';
import Tracking from 'services/Tracking';

import { discountCodeVerify } from 'requests/discountCodeVerify';

import DeviceContext from 'components/context/Device';
import LocaleContext from 'components/context/Locale';
import MarketContext from 'components/context/Market';
import { UserPanelContextProvider } from 'components/context/UserPanel';

const WrapperAuth = dynamic(() => import('components/wrappers/Auth'), { ssr: true });
const WrapperPanel = dynamic(() => import('components/wrappers/Panel'), { ssr: true });

import Locale from 'models/Locale';

import { debounce } from 'utils/debounce';

import StyledComponent from './styles';
import { Props } from './types';

import 'moment/locale/pl';

// Posibility to dynamic set timezone
// TODO: Need to be changed before UK (valid?)
moment.tz.setDefault('Europe/Warsaw');
moment.locale('pl');

const WrapperApp: NextPage<Props> = ({
    actions,
    state,
    children,
    market,
    isMobile,
    isBot,
    navLocations,
    navArticles,
    productCategories,
}) => {
    const router = useRouter();
    const [defaultLocale, setDefaultLocale] = useState<Locale>(market?.locales[0]);
    const [isMobileOnResizeCheck, setIsMobileOnResizeCheck] = useState<boolean>(isMobile);
    const [isMobileNavigation, setIsMobileNavigation] = useState<boolean>(isMobile);
    const [lang, setLang] = useState<Locales>(defaultLocale?.code as Locales);
    const translationsCommon = selectLocale({
        [Locales.En]: require('locales/common/en.json'),
        [Locales.Pl]: require('locales/common/pl.json'),
    });

    useEffect(() => {
        if (market?.locales.length > 0) {
            setDefaultLocale(market?.locales[0]);
        }
    }, [market?.locales.length]);

    useEffect(() => {
        setLang(defaultLocale?.code as Locales);
    }, [defaultLocale]);

    useEffect(() => {
        if (router?.query?.code) {
            verifyDiscountCode(router?.query?.code.toString());
        }
    }, [router?.query]);

    useEffect(() => {
        if (isMobile) return;

        function handleResize() {
            if (window.innerWidth <= 1024) setIsMobileNavigation(true);
            else setIsMobileNavigation(false);

            if (window.innerWidth < 640) setIsMobileOnResizeCheck(true);
            else setIsMobileOnResizeCheck(false);
        }

        window.addEventListener('resize', debounce(handleResize, 100));

        return () => {
            window.removeEventListener('resize', debounce(handleResize, 100));
        };
    }, []);

    useEffect(() => {
        //set globals
        moment.locale('pl');

        //init tracking
        Tracking.init(market?.gtmId);

        //set initial MobileNav if proper window width
        if (window.innerWidth <= 1024) setIsMobileNavigation(true);

        //set visitor cookie
        let visitorUniqueId = getProvider().get(CookieKeys.Visitor);
        if (!visitorUniqueId) {
            visitorUniqueId = generateHash();
            getProvider().set(CookieKeys.Visitor, visitorUniqueId);
        }

        //submit/set visitor
        const visitorResource = LocalStorage.getItem(LocalStorageKeys.Visitor);
        if (visitorResource && visitorResource?.uniqueId === `${market.slug}_${visitorUniqueId}`) {
            actions.setVisitor({ visitor: new Visitor(visitorResource) });
        } else {
            actions.submitVisitor({ uniqueId: visitorUniqueId });
        }

        //set authToken
        if (!state.authToken) {
            const authToken = getProvider().get(CookieKeys.AuthToken) || null;
            if (authToken) {
                actions.setAuthToken({ authToken });
            }
        }
    }, []);

    useEffect(() => {
        if (!state?.visitor?.uniqueId) return;
        try {
            const cartResource = LocalStorage.getItem(LocalStorageKeys.Cart);

            if (cartResource) {
                actions.setCurrentCart({ cart: new Cart(cartResource) });
                refreshCart();
            }

            if (!cartResource) {
                refreshCart();
            }
        } catch (error) {
            console.error(error);
        }
    }, [state?.visitor?.uniqueId]);

    useEffect(() => {
        // Authorize user
        authenticate();
    }, [state.authToken, state.profile]);

    useEffect(() => {
        if (state.profile) {
            Tracking.eventAuthentication(state.profile.id);
        }
    }, [router.asPath, !state.profile]);

    const refreshCart = () => {
        actions.currentCart().catch((error) => {
            console.error(error);

            //resubmit visitor
            const visitorUniqueId = generateHash();
            getProvider().set(CookieKeys.Visitor, visitorUniqueId);
            actions.submitVisitor({ uniqueId: visitorUniqueId });
            actions.currentCart();
        });
    };

    const authenticate = (): void => {
        if (!state.authToken) {
            return;
        }
        if (!state.profile) {
            actions.profileGet();
        }
    };

    const renderWrapper = (): ReactNode => {
        if (!router?.asPath) {
            return null;
        }

        switch (getWrapperName(router.asPath)) {
            case Routes.PrefixPanel:
                return (
                    <WrapperAuth>
                        <WrapperPanel>
                            {React.cloneElement(children, { lang })}
                        </WrapperPanel>
                    </WrapperAuth>
                );

            case Routes.PrefixAccount:
                return React.cloneElement(children, { lang });
            default:
                return React.cloneElement(children, { lang });
        }
    };

    const verifyDiscountCode = async (discountCode?: string) => {
        await discountCodeVerify({ code: discountCode })
            .then(() => {
                LocalStorage.setItem(LocalStorageKeys.CartDiscountCode, { code: router?.query?.code });
                toast.info(translationsCommon?.messages?.discounCodeSaved?.replace('{{code}}', router?.query?.code));
            })
            .catch(() => toast.error(translationsCommon?.messages?.discounCodeError));
    };

    const localeData = useMemo(() => {
        return getLocale(lang);
    }, []);

    const rerenderWhenBlogArticleChangedOrPathNameBased = router.asPath === Routes.PublicBlogArticle ? router.asPath : router.pathname;

    const wrapper = useMemo(() => {
        return renderWrapper();
    }, [rerenderWhenBlogArticleChangedOrPathNameBased]);

    return (
        <StyledComponent className="wrapper-app">
            <UserPanelContextProvider>
                <DeviceContext.Provider value={{ isMobile: isMobileOnResizeCheck, isBot, isMobileNavigation }}>
                    <MarketContext.Provider value={{ market, locations: navLocations, articles: navArticles, productCategories }}>
                        <LocaleContext.Provider
                            value={{
                                locale: lang,
                                translations: localeData,
                                config: getLocaleConfig(lang),
                            }}
                        >
                            {wrapper}
                        </LocaleContext.Provider>
                    </MarketContext.Provider>
                </DeviceContext.Provider>
            </UserPanelContextProvider>
        </StyledComponent>
    );
};

export default WrapperApp;
